<template>
    <section class="gallery-view"> 
        <h1>GALERIE</h1>

        <gallery-filters @filter-gallery="(orderGallery)"/>

        <h2>{{ filterGallery }}</h2>

        <ul class="grid-wrapper">
            <li :key="index" v-for="(image, index) in showImages" :class="image.type">
                <img :src="require('@/assets/images/' + image.name)" @load="image.load = true" :class="image.load ? '' : 'black'" @click="fullScreen = image.name, toggleFullScreen = !toggleFullScreen">
            </li>
            <li class="intersection-watcher"></li>
        </ul>

        <gallery-full-screen-comp v-if="toggleFullScreen" :fullScreenImg="fullScreen"  @toggleFullScreen="(toggle) => toggleFullScreen = toggle"/>
    </section>
</template>

<script>
    import GalleryFilters from '@/components/features/GalleryFilters.vue';
    import GalleryFullScreenComp from '@/components/features/GalleryFullScreenComp.vue';

    export default {
        name: 'GalleryView',

        components : { GalleryFilters, GalleryFullScreenComp },

        data() {
            return {                
                fullScreen : 'basket-shoot.jpg',
                toggleFullScreen : false,

                filterGallery : 'all gallery',

                images: [
                    {
                        name : 'cinematique/cinematique01.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique02.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique03.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique04.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique05.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique06.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique07.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique08.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique09.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique10.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique11.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique12.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique13.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique14.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique15.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique16.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique17.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique18.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique19.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique20.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique21.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique22.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique23.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique24.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique25.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique26.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique27.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique28.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique29.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique30.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique31.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique32.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique33.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique34.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique35.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique36.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique37.jpg',
                        type : 'big',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique38.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique39.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique40.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique41.jpg',
                        type : 'tall',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique42.jpg',
                        type : 'normal',
                        load : false,
                        category : 'cinematique'
                    },
                    {
                        name : 'cinematique/cinematique43.jpg',
                        type : 'wide',
                        load : false,
                        category : 'cinematique'
                    },



                    {
                        name : 'portrait/portrait01.jpg',
                        type : 'normal',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait02.jpg',
                        type : 'big',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait03.jpg',
                        type : 'tall',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait04.jpg',
                        type : 'wide',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait05.jpg',
                        type : 'normal',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait06.jpg',
                        type : 'wide',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait07.jpg',
                        type : 'wide',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait08.jpg',
                        type : 'big',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait09.jpg',
                        type : 'tall',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait10.jpg',
                        type : 'normal',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait11.jpg',
                        type : 'normal',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait12.jpg',
                        type : 'big',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait13.jpg',
                        type : 'wide',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait14.jpg',
                        type : 'tall',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait15.jpg',
                        type : 'normal',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait16.jpg',
                        type : 'wide',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait17.jpg',
                        type : 'big',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait18.jpg',
                        type : 'big',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait19.jpg',
                        type : 'tall',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait20.jpg',
                        type : 'normal',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait21.jpg',
                        type : 'wide',
                        load : false,
                        category : 'portrait'
                    },
                    {
                        name : 'portrait/portrait22.jpg',
                        type : 'tall',
                        load : false,
                        category : 'portrait'
                    },


                    {
                        name : 'produits/produits01.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits02.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits03.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits04.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits05.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits06.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits07.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits08.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits09.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits10.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits11.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits12.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits13.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits14.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits15.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits16.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits17.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits18.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits19.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits20.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits21.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits22.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits23.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits24.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits25.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits26.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits27.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits28.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits29.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits30.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits31.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits32.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits33.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits34.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits35.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits36.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits37.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits38.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits39.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits40.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits41.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits42.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits43.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits44.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits45.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits46.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits47.jpg',
                        type : 'wide',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits48.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits49.jpg',
                        type : 'normal',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits50.jpg',
                        type : 'big',
                        load : false,
                        category : 'produits'
                    },
                    {
                        name : 'produits/produits51.jpg',
                        type : 'tall',
                        load : false,
                        category : 'produits'
                    },
                ],

                imagesFiltered : [],
                showImages : [],

                index : 0
            }
        },

        mounted(){
            window.scrollTo(0,0);
            this.orderGallery('all gallery')
            this.imagesFiltered.sort(()=> Math.random() - 0.5);
        },

        created () {
            this.imagesFiltered = this.images;
        },

        methods: {
            handleIntersect(entries){
                let max = this.imagesFiltered.length;

                if(entries[0].isIntersecting){
                    for (let i = this.index; i < this.index + 10 && i < max; i++) {
                        if (this.imagesFiltered[i]) {
                            this.showImages.push(this.imagesFiltered[i]);
                        }
                    }

                    this.index = this.index + 10;
                }                    
            },

            orderGallery(filter) {
                this.showImages = [];
                this.imagesFiltered = [];

                this.filterGallery = filter; 

                if(this.filterGallery == "all gallery"){
                    this.imagesFiltered = this.images;
                } else {                  
                    this.images.forEach(image => {
                        if(image.category == this.filterGallery){
                            this.imagesFiltered.push(image);
                        }
                    });                    
                }

                this.index = 0;

                const watcher = document.querySelector('.intersection-watcher');
                new IntersectionObserver(this.handleIntersect).observe(watcher);  
            }
        },
    }
</script>

<style lang="scss">
    .gallery-view {
        padding: 90px 1em 1em 1em;

        h1 {
            font-size: 6em;
            color: white;
            letter-spacing: .5em;

            @include mobile {
                font-size: 2em;
            }
        }

        h2 {
            background: white;
            color: black;
            padding: .5em 1em;
            margin-bottom: 2em;
        }

        img {
            max-width: 100%;
            height: auto;
            vertical-align: middle;
            display: inline-block;
            cursor: pointer;
            opacity: 1;
            transition: all 3s;
        }

        .black {
            background: #eee;
            background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
            border-radius: 5px;
            background-size: 200% 100%;
            animation: 1.5s shine linear infinite;
        }

        .grid-wrapper {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-auto-rows: 200px;
            grid-auto-flow: dense;

            @include mobile {
                grid-template-columns: 1fr 1fr;
            }

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: .5em;
                overflow: hidden;
                opacity: 0; 
                transform: scale(1) translateY(-10px);  
                animation: fade-in .4s ease-out forwards;                 

                &:hover {
                    transform: scale(1.02);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .normal {
                grid-column: auto;
            }

            .wide {
                grid-column: span 2;                
            }

            .tall {
                grid-row: span 2;            
            }

             .big {
                grid-column: span 2;
                grid-row: span 2;             
            }
        }

        @keyframes shine {
            to {
                background-position-x: -200%;
            }
        }

        @keyframes fade-in {
            to {
                transform: scale(1) translateY(0);
                opacity: 1;
            }
        }
    }
</style>
