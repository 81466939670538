import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GalleryView from '../views/GalleryView.vue'
import LegalsNoticesView from '../views/LegalsNoticesView.vue'
import PrivaciesPolicies from '../views/PrivaciesPoliciesView.vue'

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: HomeView
  },

  {
    path: '/visioon-gallery',
    name: 'gallery',
    component: GalleryView
  },

  {
    path: '/mentions-legales',
    name: 'legals-notices',
    component: LegalsNoticesView
  },

  {
    path: '/politique-de-confidentialite',
    name: 'privacies-policies',
    component: PrivaciesPolicies
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
