<template>
    <section class="view-legals-notices">
        <h1>MENTIONS LEGALES</h1>

        <article class="content-legals-notices">
            <h2>PUBLICATION</h2> 

            <p>Le site « <a href="https://visioon.fr/">visioon.fr</a> » est edite et exploite par l'auto-entreprise (en creation pour le moment) de Guillaume Gallardo, exercant sous le numero SIREN ****, au 64000 Bayonne, France. <br/>
            Le Directeur de la publication est Guillaume Gallardo. <br/>
            Le site web est heberge par gandi.net (<a href="https:www.gandi.net/fr">www.gandi.net/fr</a>) – 63-65 boulevard Massena 75013 Paris.</p>

            <h2>PROPRIETE INTELLECTUELLE</h2>

            <p>Ce site web est protege par les droits de propriete intellectuelle et sont la propriete exclusive de Guillaume Gallardo. L’ensemble du site web qu’il s’agisse de son architecture, des graphismes, logos, des photographies, des videos ainsi que l’ensemble des publications et contenus constituent des oeuvres originales eligibles a la protection au titre du droit d’auteur. Toute reproduction, integrale ou partielle, tout adaptation, toute traduction, tout arrangement est illicite sans le consentement de l’auteur. Toute reproduction ou toute exploitation non autorisee des contenus presents sur ce site engage la responsabilite de son auteur et l’expose a des risques de poursuite, notamment sur le fondement de la contrefacon.</p>

            <h2>LIENS HYPERTEXTES</h2>

            <p>Le site <a href="https://visioon.fr/">visioon.fr</a> contient un certain nombre de liens hypertextes vers d’autres sites. Cependant, Guillaume Gallardo n’exerce aucun controle sur le contenu des sites tiers et ne saurait en consequence assumer aucune responsabilite a ce titre.</p>

            <h2>EXONERATION DE RESPONSABILITe</h2>

            <p>Guillaume Gallardo decline toute responsabilite quant a l’utilisation qui pourrait etre faite des informations et contenus presents sur ce site. Il s’engage a faire les meilleurs efforts pour securiser son site. Neanmoins, aucun site n’etant inviolable, sa responsabilite ne pourra etre mise en cause si des donnees indesirables sont importees et installees sur son site a son insu, ou si a l’inverse, les donnees transmises par l’utilisateur dans le cadre de l’utilisation du site sont detournees pour etre utilisees illicitement par des tiers.</p>
            
            <h2>INFORMATIONS COMPLEMENTAIRES</h2>

            <p>La consultation ainsi que l’utilisation du site <a href="https://visioon.fr/">visioon.fr</a> sont soumis a l’acceptation de sa politique de confidentialite. Pour toutes questions repondant a l’utilisation des cookies, de la confidentialite ou du traitement de vos donnees, merci de vous referer a notre <a href="https://visioon.fr/politique-de-confidentialite">politique de confidentialite</a>.</p>
        </article>
    </section>
</template>

<script>
    export default {
        name: 'LegalsNoticesView',
        
        mounted () {
            window.scrollTo(0,0);
        },
    }
</script>

<style lang="scss">
    .view-legals-notices {
        color: white;
        margin: 100px auto; 
        width: 85%;

        h1 {
            font-size: 3.5em;

            @include mobile {
                font-size: 2.1em;
            }
        }
    
        .content-legals-notices {

            h2 {
                text-align: start;
                font-size: 1.6em;
                margin-bottom: .5em;
                margin-top: 2em;

                @include mobile {
                    font-size: 1.4em;
                }
            }

            p {
                font-size: 0.9em;
            }

            
            a {
                text-decoration: underline;
                color: $color-text;

                &:hover {
                    padding: .1em;
                    border-radius: .5em;
                    background: white;
                    color: black;
                }
            }
        }
    }
</style>