<template>
    <div class="responsive-carousel-photo" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
        <swiper :grabCursor="true" :effect="'creative'" :creativeEffect="{ prev: { shadow: true, translate: [0, 0, -400], }, next: { translate: ['100%', 0, 0], },}" :modules="modules" class="mySwiper">
            <swiper-slide :key="index" v-for="(image, index) in images">
                <img :src="require('@/assets/images/gallery/' + image.name)">
                <i class="fa-solid fa-arrow-left"></i>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/effect-creative';
  import { EffectCreative } from 'swiper';

  export default {
    name: 'ResponsiveCarouselPhoto',

    components: { Swiper, SwiperSlide },

    data() {
        return {
            images: [
                {
                    name : 'basket.jpg',
                    position : '0'
                },
                {
                    name : 'facture.jpg',
                    position : '1'
                },
                {
                    name : 'portugal.jpg',
                    position : '2'
                },
                {
                    name : 'street.jpg',
                    position : '3'
                },
                {
                    name : 'pollen.jpg',
                    position : '4'
                },
            ],
        }
    },
    
    setup() {
      return {
        modules: [EffectCreative],
      };
    },
  };
</script>

<style lang="scss">
    .responsive-carousel-photo {
        padding: 1em 0 3em 1em;
        position: relative;
        box-shadow: 0px 20px 10px 5px rgb(0, 0, 0);
        z-index: 1;
        display: none;
        
        @include mobile {
            display: flex;
        }

        .swiper {
            width: 100%;
            height: 200px;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            font-weight: bold;
            color: #fff;
            border-radius: 1em 0 0 1em;
            position: relative;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            i {
                position: absolute;
                top: 50%;
                left: 1.5em;
                font-size: .7em;
                animation: on-left 2s infinite;
            }

            @keyframes on-left {
                0% {
                    left: 1.5em;
                } 
                
                50% {
                    left: .5em;
                }

                100% {
                    left: 1.5em;
                }
            }
        }
    }
</style>