<template>
    <ul class="gallery-filters">
        <li class="card" :key="index" v-for="(filter, index) in filters" @click="$emit('filter-gallery', filter.title)">
            <img :src="require('@/assets/images/gallery/' + filter.imageName)">
            <h2 class="card-title">{{ filter.title }}</h2>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'GalleryFilters',

        data() {
            return {
                filters: [
                    {
                        title : 'all gallery',
                        imageName : 'all-gallerie.png'
                    },

                    {
                        title : 'produits',
                        imageName : 'parfum-fruits.jpg'
                    },

                    {
                        title : 'portrait',
                        imageName : 'street.jpg'
                    },

                    {
                        title : 'cinematique',
                        imageName : 'cinematique34.jpg'
                    },
                ]
            }
        },
    }
</script>

<style lang="scss">
    .gallery-filters {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1em 1em 3em 1em;
        overflow: hidden;
        transform: skew(5deg);

        @include mobile {
            width: 96%;
        }

        .card {
            border: solid 2px white;
            position: relative;
            flex: 1;
            height: 250px;
            transition: all 1s linear;
            overflow: hidden;
            width: 100%; 

            .card-title {
                color: $color-primary;
                background: $color-secondary;
                padding: 0.5em;
                transform: rotate(-90deg);
                transform-origin: 0% 0%;
                transition: all 0.5s ease-in-out;
                min-width: 100%;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: .8em;
                white-space: nowrap;
                letter-spacing: .3em;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @include mobile {
                    font-size: .7em;
                }               
            }

            &:hover {
                flex-grow: 8;

                img {
                    filter: grayscale(0);
                }

                .card-title {
                    text-align: center;
                    top: calc(100% - 1em);
                    color: white;
                    background: rgba(0, 0, 0, 0.5);
                    font-size: 2em;
                    transform: rotate(0deg) skew(0deg);

                    @include mobile {
                        font-size: .8em;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all 1s ease-in-out;
                //filter: grayscale(100%);
            }

            &:not(:nth-child(5)) {
                margin-right: 1em;
            }
        }
    }
</style>