<template>
    <section class="view-privacy-policies">
        <h1>POLITIQUE DE CONFIDENTIALITe</h1>

        <article class="privacy-policies">
            <h2>PREAMBULE</h2>

            <p>La presente politique de confidentialite s’adresse a tous les utilisateurs du site <a href="https://visioon.fr/">visioon.fr/</a> cree et edite par Guillaume Gallardo.</p>

            <p>Son contenu vise a informe l’utilisateur sur le traitement et la sauvegarde de ces donnees personnelles.</p>

            <p>Guillaume Gallardo, se voulant en conformite avec la legislation RGPD (<a href="https://www.economie.gouv.fr/entreprises/reglement-general-sur-protection-des-donnees-rgpd">Le Reglement General a la Protection des Donnees</a>) vous propose une information transparente et precise sur l’utilisation, le traitement et la sauvegarde de vos donnees.</p>

            <h2>RESPONSABLE DU TRAITEMENT DES DONNEES</h2>

            <p> Le responsable du traitement des donnees est Monsieur Guillaume Gallardo. Les coordonnees de contact sont les suivantes :  <a href="mailto:contact@visioon.fr">contact@visioon.fr</a></p>

            <h2>FINALITE DE LA COLLECTE DES DONNEES PERSONNELLES</h2>

            <p>Vos donnees sont collectees parce que vous acceptez de nous les communiquer dans la finalite d'obtenir un contact client. De meme, la finalite de ce traitement est de nous permettre de repondre aux questions que vous nous adressez et qui peuvent porter notamment sur nos prestations, l’exercice de vos droits ainsi que toute autre information. Il a pour fondement notre interet legitime de pouvoir repondre a vos questions.</p>

            <h2>DUReE DE CONSERVATION DES DONNeES PERSONNELLES</h2>

            <p>Conformement a la legislation et reglementation en vigueur, vos donnees sont conservees pour la duree strictement necessaire aux finalites poursuivies telles que decrites ci-dessus. La duree de conservation des donnees transmises via notre formulaire de contact sera d'un maximum de 3 ans.</p>

            <h2>DESTINATAIRE DES DONNeES COLLECTeES</h2>
            
            <p>Les donnees collectees sont reservees a un usage purement interne. Nous ne procedons a aucune cession ni commercialisation de vos donnees.</p>

            <h2>LES INFORMATIONS COLLECTEES</h2>

            <p>Lors d’une demande de contact, les informations collectees sont : </p>

            <ul>
                <li>Nom & Prenom</li>
                <li>Adresse email</li>
                <li>Telephone</li>
            </ul>

            <p>Nous ne demandons, traitons et collectons que les informations strictement necessaires pour le traitement d’une demande de contact. </p>

            <h2>EXERCICE DE VOS DROITS</h2>
            
            <p>Conformement a la Loi Informatique et Libertes et la reglementation europeenne sur la protection des donnees personnelles, vous disposez de droits sur les donnees vous concernant : </p>

            <ul>
                <li>
                    Droit d’acces : Vous avez le droit de nous demander confirmation que vos donnees font bien l’objet d’un traitement et obtenir les informations relatives a ce traitement (finalites, destinataires etc…).
                </li>
                <li>
                    Droit de rectification et d’effacement des donnees : Vous pouvez demander la rectification de vos donnees lorsqu’elles sont inexactes ou incompletes. Vous pouvez egalement demander l’effacement de vos donnees sous reserve de nos obligations legales de conservation.
                </li>
                <li>
                    Droit a la limitation du traitement : vous pouvez demander la limitation du traitement de vos Donnees, sous reserve du respect des obligations legales.
                </li>
                <li>
                    Droit a la limitation du traitement : vous pouvez demander la limitation du traitement de vos Donnees, sous reserve du respect des obligations legales.
                </li>
                <li>
                    Droit d’opposition : vous pouvez vous opposer a tout moment au traitement de vos donnees, sauf en cas de motifs legitimes et imperieux de notre part. Vous pouvez egalement vous opposer au traitement fait a des fins de prospection.
                </li>
                <li>
                    Droit a la portabilite : si les exigences legales sont remplies, vous pouvez demander a recevoir vos donnees dans un format structure, couramment utilise, lisible par machine et interoperable, et de les transmettre a un autre responsable du traitement sans que nous y fassions obstacle.
                </li>
                <li>
                    Droit de ne pas faire l’objet d’une decision fondee sur un traitement automatise : vous avez le droit de ne pas faire l’objet d’une decision fondee exclusivement sur un traitement automatise produisant des effets juridiques vous concernant ou vous affectant, sauf lorsque cette decision est necessaire a la conclusion ou a l’execution d’un contrat, ou est autorisee legalement. Nous vous informons que nous ne prenons aucune decision automatisee.
                </li>
                <li>
                    Reclamations : vous avez le droit d’introduire une reclamation aupres d’une autorite de controle.
                </li>
                <li>
                    Directives en cas de deces : vous avez le droit de donner des directives pour le sort de vos donnees personnelles en cas de deces.
                </li>
            </ul>

            <br/>

            <p>Vous trouverez ici une information concernant l’exercice de ces droits : <a href="https://www.cnil.fr/fr/comprendre-vos-droits">CNIL</a></p> 

            <br/>

            <p>Pour exercer vos droits, il vous suffit de contacter le responsable par courriel a l’adresse de contact suivante : <a href="mailto:contact@visioon.fr">contact@visioon.fr</a></p>

            <br/>

            <p>Conformement a la reglementation en vigueur, votre demande doit etre signee et accompagnee de la photocopie d’un titre d’identite portant votre signature et preciser l’adresse a laquelle doit vous parvenir la reponse. Une reponse vous sera alors adressee dans un delai d’1 mois maximum a compter de la reception de la demande.</p>

            <h2>LIENS HYPERTEXTES</h2>

            <p>Le site visioon.fr contient un certain nombre de liens hypertextes vers d’autres sites. Cependant, nous n’avons pas la possibilite de verifier le contenu des sites ainsi visites, et nous n’assumons en consequence aucune responsabilite de ce fait.</p>

            <h2>DEFINITION D'UN COOKIE</h2>

            <p>Un cookie est un petit fichier texte stocker dans votre navigateur, toutes plateformes confondues, lors de la visite de notre site visioon.fr afin de recuperer ou de stocker des informations.</p>

            <p>Il est important de preciser que les cookies sont soumis a votre consentement, excepte les cookies strictement necessaires au bon fonctionnement du site. Vous avez par consequent, la possibilite de choisir de les utiliser ou non en fonction de vos preferences.</p>

            <h2>LES COOKIES EMIS PAR CE SITE</h2>

            <ul>
                <li>Les cookies strictement necessaires
                    Les cookies necessaires sont cruciaux pour les fonctions de base du site Web et celui-ci ne fonctionnera pas comme prevu sans eux. Ces cookies ne stockent aucune donnee personnellement identifiable.
                </li>

                <li>Les cookies tiers
                    Notre site contient un certains nombre de cookies tiers tels que des boutons de medias sociaux. 
                </li>
            </ul>

            <h2>MODIFICATIONS APPORTEES a LA PReSENTE POLITIQUE DE CONFIDENTIALITe</h2>

            <p>Nous nous reservons le droit de modifier la presente politique de confidentialite a tout moment. Nous vous invitons donc a la consulter frequemment. Les changements et les clarifications prendront effet immediatement apres leur publication sur le site web.</p>


            <h2>QUESTIONS ET COORDONNeES</h2>

            <p>Pour toutes questions relatives a la presente politique de confidentialite, nous vous invitons a contacter Monsieur Guillaume Gallardo par courriel a l'adresse <a href="mailto:contact@visioon.fr">contact@visioon.fr</a>.</p>
        </article>
    </section>
</template>

<script>
    export default {
        name: 'PrivacyPoliciesView',
        
        mounted () {
            window.scrollTo(0,0);
        },
    }
</script>

<style lang="scss">
    .view-privacy-policies {
        color: white;
        margin: 100px auto; 
        width: 85%;

        h1 {
            font-size: 3.5em;

            @include mobile {
                font-size: 1.4em;
            }            
        }

        .privacy-policies {
            h2 {
                text-align: start;
                font-size: 1.6em;
                margin-bottom: .5em;
                margin-top: 2em;

                @include mobile {
                    font-size: 1.4em;
                }                
            }

            p {
                font-size: 0.9em;
            }

            ul {
                list-style-type: decimal;

                li {
                    margin: 1em 0;
                    position: relative;
                    left: 2em;

                    @include mobile {
                        left: 1em;
                    }
                }
            }

            a {
                text-decoration: underline;
                color: $color-text;

                &:hover {
                    padding: .1em;
                    border-radius: .5em;
                    background: white;
                    color: black;
                }
            }
        }
    }
</style>