<template>
  <div class="home">
    <hero-comp/>
    <video-section-comp/>
    <photo-section-comp/>
    <carousel-gallery-comp/>
    <responsive-carousel-photo/>
    <about-me-comp/>
    <contact-section-comp/>
  </div>
</template>

<script>
  import HeroComp from '@/components/section/HeroComp';
  import VideoSectionComp from '@/components/section/VideoSectionComp';
  import PhotoSectionComp from '@/components/section/PhotoSectionComp';
  import AboutMeComp from '@/components/section/AboutMeComp';
  import CarouselGalleryComp from '@/components/section/CarouselGalleryComp';
  import ResponsiveCarouselPhoto from '@/components/section/ResponsiveCarouselPhoto';
  import ContactSectionComp from '@/components/section/ContactSectionComp';

  export default {
    name: 'HomeView',
    
    components : { 
      HeroComp,
      VideoSectionComp,
      PhotoSectionComp,
      CarouselGalleryComp,
      ResponsiveCarouselPhoto,
      AboutMeComp,
      ContactSectionComp,
    },

    mounted () {
      window.scrollTo(0,0);
    }
  }
</script>
