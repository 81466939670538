<template>
    <div class="footer-comp">

        <hr>

        <div class="contain-footer">
            <router-link to="/"><img src="@/assets/images/graphic-identity/picto-v.jpg" alt=""></router-link>
            
            <ul>
                <li>
                    <router-link to="/visioon-gallery">Galerie</router-link>
                </li>

                <li>
                    <a href="https://visioon.fr/#about-me-comp">A propos</a>
                </li>

                <li>
                    <a href="https://visioon.fr/#contact-section-comp">Contact</a>                
                </li>
            </ul>

            <ul>
                <li>
                    <router-link to="/politique-de-confidentialite">Politique de confidentialite</router-link>
                </li>

                <li>
                    <router-link to="/mentions-legales">Mentions Legales</router-link>
                </li>
            </ul>

            <a href="https://www.instagram.com/_visioon_/" target="_blanck"><i class="fa-brands fa-instagram"></i></a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterComp'
    }
</script>

<style lang="scss">
    .footer-comp {
        padding: 2em;

        hr {
            background: white;
            width: 80%;
            height: 2px;
            margin: 2em auto 3em;

            @include mobile {
                margin: 2em auto 2em;
            }
        }
        
        .contain-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @include mobile {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1em;
            }

            li {
                margin-bottom: .3em;
                
                @include mobile {
                    text-align: center;
                }

                a:hover {
                    padding: .3em .5em;
                    border-radius: .5em;
                    background: white;
                    color: black;
                }
            }

            a {
                color: $color-text;
                font-size: .9em;

                img {
                    width: 35px;
                }

                i {
                    font-size: 2.5em;
                }
            }
        }
    }
</style>