<template>
<div id="app">
    <nav-comp/>
    <router-view/>  
    <footer-comp/>
  </div>
</template>

<script>
  import NavComp from '@/components/navigation/NavComp';
  import FooterComp from '@/components/navigation/FooterComp';

  export default {
    components : { NavComp, FooterComp }
  }
</script>

<style lang="scss">
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    opacity: 1;
    scroll-behavior: smooth;
    transition: .5s;
  }

  h1, h2, h3, h4, a, button, span {
    font-family: $font-global;
  }

  p, label, input, select, textarea, li {
    text-transform: uppercase;
    font-family: $font-text;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
  
  body {
      background: $color-primary;
      
      @include xxl {
        padding: 0 15em;
      }
  }

  .btn-action {
    background: $color-primary;
    color: $color-text;
    border: solid 1px $color-secondary;
    padding: .8em 1em;
    
    &:hover {
      color: $color-primary;
      background: $color-secondary;
    }
  }
</style>
