<template>
    <section id="contact-section-comp">
        <article>
            <h2 v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">CONTACT</h2>

            <form id="formContact">
                <div class="form-group">
                    <div class="block-group" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                        <label for="input-name">Prenom & Nom</label>
                        <input type="text" id="input-name" name="name" v-model="name">
                    </div>

                    <div class="block-group" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                        <label for="input-email">Adresse email</label>
                        <input type="email" id="input-email" name="email" v-model="email">
                    </div>
                </div>

                <div class="form-group">
                    <div class="block-group" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                        <label for="input-phone">Phone</label>
                        <input type="tel" id="input-phone" name="phone" v-model="phone">
                    </div>

                    <div class="block-group" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                        <label for="select-domain">Domaines</label>
                        <select id="select-domain" name="domain"  v-model="domain">
                            <option>Video</option>
                            <option>Photo</option>
                            <option>Autre</option>
                        </select>
                    </div>
                </div>

                <div class="block-group" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                    <label for="input-message">Un message ?</label>
                    <textarea id="input-message" name="message" v-model="message"></textarea>                    
                </div>

                <div class="inline-group" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                    <input type="checkbox" id="accept" name="accept" v-model="checkbox">
                    <label for="accept" id="privacy-policy-check">En cliquant sur le bouton "Envoyer", je reconnais avoir pris connaissance et etre en accord avec la politique de confidentialite</label>
                </div>

                <aside class="msg-error" v-if="msgError != ''">{{ msgError }}</aside>

                <button @click.prevent="sendEmail" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">{{ btnLabel }}</button>

                <aside class="form-completed" v-if="formCompleted == true">
                    <p>Votre email a bien ete envoye, je vous recontacterai rapidement.</p>
                </aside>
            </form>
        </article>

        <div class="contact-img" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
            <img src="@/assets/images/all/contact.png" alt="">
        </div>
    </section>
</template>

<script>
    import emailjs from '@emailjs/browser';

    export default {
        name: "ContactSectionComp",

        data() {
            return {
                name: '',
                email: '',
                phone: '',
                domain: 'Autre',
                message: '',
                checkbox: '',
                btnLabel: 'Envoyer',
                msgError: '',
                formCompleted: false
            }
        },

        methods: {
            sendEmail(){
                const regexEmail = /\S+@\S+\.\S+/;

                if(this.name != '' && this.email != '' && this.phone != '' && this.domain != '' && this.message != ''){
                    if(this.email.search(regexEmail) === 0){
                        if(this.checkbox != ''){
                            this.btnLabel = 'Envoi en cours..'

                            emailjs.sendForm('service_fh7sbl1' , 'template_58lrnov' , '#formContact', 'fyIMtsWZleLxCFJhT')
                            .then(response => {
                                if(response.status === 200){
                                    this.formCompleted = true;

                                    this.name = '';
                                    this.email = '';
                                    this.phone = '';
                                    this.domain = ''; 
                                    this.message = '';
                                    this.checkbox = '';
                                    this.msgError = '';
                                    this.btnLabel = 'Envoyer';
                                }
                            })
                            .catch(error => console.log(error));
                        } else {
                            this.formCompleted = true;
                            this.msgError = 'Vous devez accepter notre politique de confidentialite pour pouvoir nous contacter';
                        }
                    } else if(this.email.search(regexEmail) === -1){
                        this.msgError = 'Veuillez entrer une addresse email valide';
                        this.formCompleted = true;
                    }
                } else {
                    this.msgError = 'Tous les champs doivent etre remplis';
                    this.formCompleted = true;
                }
            }
        },
    }
</script>

<style lang="scss">
    #contact-section-comp {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        gap: 3em;
        color: $color-secondary;
        padding: 1em;
        margin-top: 8em;
        position: relative;
        box-shadow: 0px -130px 15px 20px rgb(0, 0, 0);
        z-index: 1;

        @include mobile {
            flex-direction: column;
            gap: 2em;
            margin-top: 3em;
            box-shadow: 0px -30px 15px 20px rgb(0, 0, 0);
        }

        article {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 3em;

            @include mobile {
                gap: 2em;
            }

            h2 {    
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 5em;
                width: 100%;

                @include mobile {
                    margin-top: 1em;
                    font-size: 2.8em;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: .5em;

                .form-group {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1em;
                    margin-bottom: .5em;

                    input, select {
                        margin-top: .3em;
                        width: 100%;
                        height: 35px;
                        border: none;
                        border-radius: .5em;
                        padding: .5em;
                        font-size: 1em;        

                        @include tablet {
                            height: 30px;
                            font-size: .9em;                           
                        }
                    }
                }

                label {
                    @include tablet {
                        font-size: .9em;                           
                    }
                }

                .block-group {
                    width: 100%;
                }

                .inline-group {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: .5em;
                    margin: .8em 0;
                }

                textarea {
                    width: 100%;
                    height: 70px;
                    resize: none;
                    border-radius: .5em;
                    padding: .5em;
                    margin-top: .3em;
                }

                #privacy-policy-check {
                    width: 80%;
                    font-size: .7em;

                    @include tablet {
                        width: 100%;
                        font-size: .5em;                           
                    }
                }

                button {
                    background: $color-secondary;
                    color: black;
                    padding: .5em;
                    border: solid 1px $color-secondary;
                    cursor: pointer;

                    &:hover {
                        background: $color-primary;
                        color: white;
                    }
                }

                .msg-error {
                    margin: 1em 0;
                    font-family: $font-global;
                    font-size: .9em; 
                    background: rgb(248, 78, 78);
                    padding: 0.5em;
                    border-radius: 1em;
                }

                .form-completed {
                    margin: 1em 0;
                    font-family: $font-global;
                    font-size: .8em; 
                    background: #239c4b;
                    padding: 0.5em;
                    border-radius: 1em;                   
                }
            }
        }

        .contact-img {
            width: 600px;
            height: 470px;
            border-radius: .5em;
            overflow: hidden;

            @include mobile {
                width: 100%;
                height: 300px;
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
</style>