<template>
    <section id="hero-comp">
      <article>
        <div class="container-animate">
          <ul>
            <li><img src="@/assets/images/graphic-identity/v.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/i.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/s.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/i2.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/o.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/o2.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/n.png" alt=""></li>
            <li><img src="@/assets/images/graphic-identity/mv.png" alt=""></li>
          </ul>
        </div>

        <h1>Photographe & Videaste</h1>
      </article>
    </section>
</template>

<script>
    export default {
        name: 'HeroComp',
    }
</script>

<style lang="scss">
  #hero-comp {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    overflow: hidden;

    article {
      color: $color-text;
      width: 700px;

      @include mobile {
        width: 280px;
      }
      
      .container-animate {
        border-top: 5px solid white;
        border-bottom: 5px solid white;
        height: 200px; 
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        animation: box-fullscreen 4s ease forwards;

        @include mobile {
          animation: box-mobile 4s ease forwards;
        }

        @include mobile {
          height: 120px;
        }


        ul {
          position: relative;

          li {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) translateX(-50%);

          }
        }

        img {
          width: 700px;
          animation: wel 4s forwards;

          @include mobile {
            width: 280px;
          }
        }

        @keyframes box-fullscreen {
          0% {
            height:0px;
            width:0%;
          }
          
          34% {
            height:0px;
            width: 100%;
          } 

          66% {
            height:200px;
          }

          90%{
            border-top: solid 5px white;
            border-bottom: solid 5px white;
          }

          100% {
            height: 200px;
            border-top: solid 0px transparent;
            border-bottom: solid 0px transparent;
          }
        }

        @keyframes box-mobile {
          0% {
            height:0px;
            width:0%;
          }
          
          34% {
            height:0px;
            width: 100%;
          } 

          66% {
            height:120px;
          }

          90%{
            border-top: solid 5px white;
            border-bottom: solid 5px white;
          }

          100% {
            height: 120px;
            border-top: solid 0px transparent;
            border-bottom: solid 0px transparent;
          }
        }

        @keyframes wel {
          0% {
            opacity:0;
          }
          100%{
            opacity:1;
          }
        }
      }

      h1 {
        font-size: 1.5em;
        color: white;
        animation: title-apear .9s 4.3s ease-in forwards;
        opacity: 0;
        margin: -30px 0 30px 0;
   
        @include mobile {
            font-size: .9em;
        }
      }

      @keyframes title-apear {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
</style>