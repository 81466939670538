<template>
    <header class="nav-comp">
        <nav>
            <router-link to="/">
                <img src="@/assets/images/graphic-identity/visioon-logo.png" alt="Logo de Visioon, entreprise de photographie et vidéo" width="120">
            </router-link>

            <div class="container-burger">
                <ul class="icon-burger" @click="burgerActive = !burgerActive">
                    <li class="bar cross" :class="[burgerActive ? 'cross-bar-1' : '']"></li>
                    <li class="bar cross" :class="[burgerActive ? 'cross-bar-2' : '']"></li>
                    <li class="bar cross" :class="[burgerActive ? 'cross-bar-3' : '']"></li>
                </ul>

                <ul class="menu-nav" :style="[burgerActive ? 'left:-110px' : 'left:300px']">
                    <li><router-link to="/visioon-gallery" @click="burgerActive = false">Galerie</router-link></li>
                    <li><a href="https://visioon.fr/#about-me-comp" @click="burgerActive = false">A propos</a></li>
                    <li><a href="https://visioon.fr/#contact-section-comp" @click="burgerActive = false">Contact</a></li>
                </ul>
            </div>
        </nav>
        
        <div class="overlay" v-if="burgerActive == true" @click="burgerActive = false"></div>
    </header>
</template>

<script>
    export default {
        name: 'NavComp',

        data() {
            return {
                burgerActive: false
            }
        },
    }
</script>

<style lang="scss">
    .nav-comp {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 1em;
        z-index: 100;
        background: black;

        .overlay {
            background: transparent;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 100;

            @include xxl {
                margin: 0 15em;
            }

            .container-burger {
                position: relative;
                
                .icon-burger {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: .3em;
                    cursor: pointer;

                    .bar {
                        width: 20px;
                        height: 4px;
                        background: $color-secondary;
                        border-radius: 1em;

                        &:nth-child(2){
                            width: 30px;
                        }
                    }

                    .cross-bar-1 {
                        transform: rotate(30deg) translateY(2px) translateX(6px);
                    }

                    .cross-bar-2 {
                        transform: translateX(-10px);
                    }

                    .cross-bar-3 {
                        transform: rotate(-30deg) translateY(-2px) translateX(6px);
                    }
                }

                .menu-nav {
                    position: absolute;
                    top: 2.5em;
                    left: 300px;
                    background: black;
                    border-left: solid 1px $color-secondary;
                    border-bottom: solid 1px $color-secondary;
                    border-radius: .5em;
                    padding: 1em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: .6em;
                    width: 200px;

                    li {
                        a {
                            color: $color-text;

                            &:hover {
                                padding: .3em .5em;
                                border-radius: .5em;
                                background: white;
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }
</style>