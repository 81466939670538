<template>
    <article class="gallery-full-screen-comp" @click="closeFullScreen()">
        <div class="full-screen-img">
            <div class="effect-container">
                <ul class="effect-apparition">
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">V</p></li>
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">I</p></li>
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">S</p></li>
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">I</p></li>
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">O</p></li>
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">O</p></li>
                    <li class="item-effect"><p :class="[animationReverse ? 'reverse' : '']">N</p></li>
                </ul>

                <img :src="require('@/assets/images/' + fullScreenImg)">
            </div>
        </div>
    </article>
</template>

<script>
    export default {
        name: 'GalleryFullScreenComp',
        props : ['fullScreenImg'],

        data() {
            return {
                animationReverse: false
            }
        },

        methods: {
            closeFullScreen() {
                this.animationReverse = true;

                setTimeout(() => {
                    this.animationReverse = false;
                    this.$emit('toggleFullScreen', false); 
                }, 2600);
                
            }
        },
    }
</script>

<style lang="scss">
    .gallery-full-screen-comp {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 64.42px);
        overflow: hidden; 
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.696);

        .full-screen-img {
            width: 100%;
            height: 95%;   
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 2em;

            .effect-container {
                position: relative;

                img {
                    object-fit: contain;
                    height: 80vh;
                    border-radius: 1em;
                }

                .effect-apparition {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: grid;
                    grid-template-columns: repeat(7, 14.3%);
                    overflow: hidden; border-radius: 1em;

                    li {
                        position: relative;                                   

                        p {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            background: black;
                            animation: slide-up .7s forwards;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                        }

                        .reverse {
                            opacity: 0;
                            height: 0%;
                            animation: slide-down .7s forwards;
                        }

                        &:nth-child(1){
                            p {
                                animation-delay: 1.2s;
                            }                       
                        }

                        &:nth-child(2){
                            p {
                                animation-delay: .9s;
                            }
                        }

                        &:nth-child(3){
                            p {
                                animation-delay: 1.4s;
                            }
                        }

                        &:nth-child(4){
                            p {
                                animation-delay: 1.6s;
                            }
                        }

                        &:nth-child(5){
                            p {
                                animation-delay: .8s;
                            }
                        }

                        &:nth-child(6){
                            p {
                                animation-delay: 1.1s;
                            }
                        }

                        &:nth-child(7){
                            p {
                                animation-delay: 1.5s;
                            }
                        }
                    }
                }
            }
        }
    }

    @keyframes slide-up {
        0% {
            opacity: 1;
            height: 100%
        }

        97% {
            opacity: 1;
        }

        100% {
            height: 0%;
            opacity: 0;
        }
    }

    @keyframes slide-down {
        0% {
            opacity: 0;
            height: 0%
        }

        100% {
            height: 100%;
            opacity: 1;
        }
    }
</style>