<template>
    <section class="carousel-gallery-comp">
        <article class="slider" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
            <div class="gallery">
                <ul>
                    <li :key="index" v-for="(image, index) in images" :class="['gallery-item', image.position == 2 && toggle == true ? 'fullScreen' : `gallery-item-${image.position}`]" @click="changePosition(index, image.position)">
                        <img :src="require('@/assets/images/gallery/' + image.name)">              
                        <i class="fa-solid  fa-up-right-and-down-left-from-center" @click="fullScreen = image.name, toggle = !toggle"></i>
        
                        <button v-if="toggle == true" @click="toggle = false">CLOSE</button> 
                    </li>
                </ul>
            </div>
        </article>

        <a href="#contact-section-comp" class="btn-action position-btn">Prendre contact</a>
    </section>
</template>

<script>
    export default {
        name: "CarouselGalleryComp",

        data() {
            return {
                fullScreen : '',

                images: [
                    {
                        name : 'basket.jpg',
                        position : '0'
                    },
                    {
                        name : 'facture.jpg',
                        position : '1'
                    },
                    {
                        name : 'portugal.jpg',
                        position : '2'
                    },
                    {
                        name : 'street.jpg',
                        position : '3'
                    },
                    {
                        name : 'pollen.jpg',
                        position : '4'
                    },
                ],

                toggle: false,
            }
        },
        
        methods: {
            changePosition(index, position) {
                this.images.forEach(image => {
                    if(image.position == 2){
                        image.position = position;
                    }
                });

                this.images[index].position = 2;
            },
        },
    }
</script>

<style lang="scss">
    .carousel-gallery-comp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 80vh;
        padding: 1em 1em 8em;
        position: relative;
        box-shadow: 0px 0px 15px 20px rgb(0, 0, 0);
        z-index: 1;
  
        @include xxl {
            height: 60vh;
        }

        @include mobile {
            display: none;
        }

        .slider {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 60vh;
            padding: 1em;

            .gallery {
                width: 100%;

                ul {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin: 0 auto;

                    .gallery-item {
                        position: absolute;
                        z-index: 0;
                        border-radius: 1em;
                        overflow: hidden;
                        transform: translateX(-50%);
                        cursor: pointer;
                        transition: 1s;
                                                                     
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }

                        i {
                            display: none;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%);
                            color: white;
                            font-size: 1.2em;
                        }

                        &:hover {
                            img {
                                transition: .5s; 
                                width: 110%;
                            }
                        }
                    }

                    .fullScreen {
                        position: relative;
                        width: 90%;
                        height: 80vh;
                        opacity: 1;
                        left: 50%;
                        z-index: 10;
                        cursor: default;
                        border-radius: 1em;

                        &:hover {
                            img {
                                transition: 0.5s;
                                width: 100%;
                            }
                        }

                        button {
                            position: absolute;
                            top: 0.5em;
                            right: 0.5em;
                            cursor: pointer;
                            border: solid 1px black;
                            background: white;
                            padding: .3em .5em;
                            border-radius: .5em;
                        }
                    }

                    .gallery-item-1, .gallery-item-3 {
                        width: 380px;
                        height: 240px;
                        opacity: .9;
                        z-index: 1;

                        @include tablet {
                            width: 300px;
                            height: 160px;
                        }
                    }

                    .gallery-item-0, .gallery-item-4 {
                        width: 330px;
                        height: 200px;
                        opacity: .4;

                        @include tablet {
                            width: 230px;
                            height: 120px;
                        }
                    }

                    .gallery-item-0 {
                        left: 15%;
                    }

                    .gallery-item-2 {
                        width: 430px;
                        height: 300px;
                        opacity: 1;
                        left: 50%;
                        z-index: 2;

                        @include tablet {
                            width: 330px;
                            height: 200px;
                        }

                        &:hover {
                            &::before{
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: rgba(0, 0, 0, 0.459);
                            }

                            i {
                                display: flex;
                            }
                        }
                    }

                    .gallery-item-1 {
                        left: 30%;
                    }

                    .gallery-item-3 {
                        left: 70%;
                    }

                    .gallery-item-4 {
                        left: 85%;
                    }
                }
            }           
        }

        .position-btn {
            margin-top: 1em;
        }
    }
</style>