import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { MotionPlugin } from '@vueuse/motion'


// import { useMotion } from '@vueuse/motion'
// import { ref }from 'vue'

// const fade = ref();

// useMotion(fade, {
//     initial: {opacity:0},
//     visible: {opacity: 1},

// })

createApp(App).use(store).use(router).use(MotionPlugin).mount('#app')
