<template>
    <section id="photo-section-comp">
            <article class="photos-gallery">
                <div class="img-container" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200"></div>
                <div class="img-container" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200"></div>
                <div class="img-container" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200"></div>
            </article>


        <article class="photo-description">
            <h2 v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">PHOTO</h2>

            <p class="alternate-text" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">Tout comme pour les vidéos, tout pleins de styles sont exécutables allant du portrait au packshot. Conceptualiser le vintage de façon moderne est une éventualité. Si vous avez votre propre marque de vêtements, parfums, sneakers ou autre. Je peux les photographier afin de les mettre un maximum en valeur et à votre image.</p>

            <p v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">Je mets mon expertise de photographe à votre disposition pour raconter votre histoire.
                Ma limite ne s’arrête qu’à votre imagination donc n’hésitez pas à me contacter pour avoir plus d’informations.
            </p>
            
            <div class="btn-container" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">
                <router-link to="/visioon-gallery" class="btn-action">Voir la galerie</router-link>
            </div>
        </article>
    </section>
</template>

<script>    
    export default {
        name: "PhotoSectionComp",
    }
</script>

<style lang="scss">
    #photo-section-comp {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        gap: 3em;
        padding: 2em;
        background-image: url('@/assets/images/graphic-identity/shader.jpg');
        background-size: cover;
        background-position: center;

        @include mobile {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 1em;
        }

        .photos-gallery {
            flex: 1;
            display: grid;
            grid-template-areas : 
                'one two'
                'three three'
            ;
            width: 100%;
            height: 440px;
            gap: 1em;

            @include mobile {
                flex: inherit;
                gap: .5em;
            }

            .img-container{
                background-size: cover;
                background-position: center;
                border-radius: .5em;

                &:nth-child(1){
                    grid-area: one;
                    background-image: url('@/assets/images/gallery/underground.jpg');
                }

                &:nth-child(2){
                    grid-area: two;
                    background-image: url('@/assets/images/gallery/nike-shoes.jpg');
                }

                &:nth-child(3){
                    grid-area: three;
                    background-image: url('@/assets/images/cinematique/cinematique27.jpg');
                }
            }
        }

        .photo-description {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 1.5em;
            color: $color-secondary;

            h2 {    
                font-size: 5em;
                width: 100%;
                text-align: end;

                
                @include mobile {
                    font-size: 3.5em;
                }
            }

            p {
                font-size: 1em;
                width: 100%;

                @include tablet {
                    font-size: .9em;
                }
            }

            .alternate-text {
                text-align: end;
            }


            .btn-container {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
</style>