<template>
    <section id="about-me-comp">
        <article>
            <h2 v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">Back to Visioon</h2>

            <p v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">Visioon incarne un univers urbain et moderne.
                Se lançant en Freelance, ce projet a débuté en 2020 en agence de communication digitale, dans laquelle de nombreuses photos et videos ont été réalisées soigneusement. Grâce à quoi il est donc possible d’éxaucer vos projets avec créativité et professionnalisme. Visioon se doit de créer des plans de tout type d’horizon, afin de répondre au mieux à vos attentes. Cette aventure ayant pour but de prolonger son univers dans un local. Un lieu parmi lequel on peut mélanger travail et divertissement, un lieu de bienveillance et bonne entente.
            </p>
        </article>
    </section>
</template>

<script>
    export default {
        name: 'AboutMeComp'
    }
</script>

<style lang="scss">
    #about-me-comp {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        gap: 3em;
        padding: 4em 2em;
        position: relative;

        @include mobile {
            padding: 2em 1em;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.5;
            background-image: url('@/assets/images/all/guillaume-gallardo.jpg');
            background-size: cover;
            background-position: center;  
        }

        article {
            position: relative;

            h2 {
                color: white;
                font-size: 5em;
                text-align: end;
                margin-bottom: .5em;
        
                @include mobile {
                    font-size: 3.5em;
                }
            }

            p {
                color: white;
                font-size: 1em;

                @include tablet {
                    font-size: .9em;
                }

                &:last-child {
                    margin-top: 2em;
                    text-align: end;
                }
            }
        }
    }
</style>