<template>
    <div class="video-section-comp">
        <article class="container-video">
            <video :src="require('@/assets/videos/' + videoPreview.videoName)" autoplay muted loop="infinite" :poster="require('@/assets/images/all/' + videoPreview.imageName)" :class="[reload ? 'video-reload' : 'video']"></video>
            
            <div class="slider-video">
                <ul>
                    <li :key="index" v-for="(image, index) in videos" :class="`item-${index}`" @click="reloadVideo(image)">
                        <div class="icon-lecture">
                            <i class="fa-regular fa-circle-pause" v-if="videoPreview.videoName == image.videoName"></i>
                            <i class="fa-regular fa-circle-play" v-if="videoPreview.videoName != image.videoName"></i>
                        </div>

                        <img :src="require('@/assets/images/all/' + image.imageName)" width="150">
                    </li>
                </ul>
            </div>
        </article>

        <article class="video-text">
            <h2 v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">CLIP</h2>

            <div class="video-description">
                <p v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">	Plusieurs types de videos sont réalisables, en passant par la mode à un clip de musique, ou même de la publicité d’un produit ou d’une marque à de l’immobilier. Il est également possible de faire des vidéos sportives par exemple. Que vous soyez un particulier ou un professionnel, faites-moi part de vos projets les plus fous. Vous me donnez vos idées et je les mets en œuvre en rajoutant une touche personnelle. Définition de l’objectif, élaboration de scénario, écriture de scripts, réalisation de tournages, montage des vidéos, post-production, diffusion… Je m’occupe de tout pour que votre projet soit unique et réponde à vos attentes.</p>
                <a href="https://www.youtube.com/@visioon_clip" target="_blanck" class="btn-action" v-motion :initial="{opacity:0}" :visible="{opacity:1}" :delay="200">Chaine Youtube</a>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name: "VideoSectionComp",

        data() {
            return {
                videoPreview : { imageName : 'rodzina-clothing.png', videoName : 'rodzina-clothing.mp4' },
                videos: [
                    {
                        imageName : 'delta.png',
                        videoName : 'delta.mp4',
                        load : false
                    },

                    {
                        imageName : 'puma.png',
                        videoName : 'puma.mp4',
                        load : false
                    },

                    {
                        imageName : 'portugal.png',
                        videoName : 'portugal.mp4',
                        load : false
                    },

                    {
                        imageName : 'villanueva.png',
                        videoName : 'villanueva.mp4',
                        load : false
                    },
                    
                    {
                        imageName : 'rodzina-clothing.png',
                        videoName : 'rodzina-clothing.mp4',
                        load : false
                    },
                ],

                reload : false
            }
        },

        methods: {
            reloadVideo(newVideo) {
                if(this.videoPreview.videoName != newVideo.videoName){
                    this.reload = true

                    setTimeout(() => {
                        this.videoPreview = newVideo;
                        this.reload = false;
                    }, 2000);
                }  
            }
        },
    }
</script>

<style lang="scss">
    .video-section-comp {
        position: relative;
        padding-bottom: 10em;
        box-shadow: 0px 10px 13px 7px rgba(0,0,0,0.85);
        z-index: 1;
        min-height: 70vh;

        @include mobile {
            padding-bottom: 3em;
        }

        .container-video {
            position: relative;
            height: 90vh;
            overflow: hidden;
            min-height: 90vh;

            video {
                object-fit: cover;
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                opacity: 1;
                transition: 2s;
            }

            .video-reload {
                opacity: 0;
                transition: 2s;
            }

            .slider-video {
                position: absolute;
                bottom: .5em;
                right: 1em;
                z-index: 5;

                @include mobile {
                    bottom: 1.5em;
                    right: .5em;
                }

                ul {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1em;

                    @include mobile {
                        display: block;
                    }

                    &:hover {
                        padding-bottom: 1em;
                        
                        .item-0, .item-1, .item-2, .item-3, .item-4 {
                            transform: translateX(0px);
                            opacity: 1;
                        }
                    }

                    li {
                        width: 150px;
                        height: 80px;
                        border-radius: .5em;
                        overflow: hidden;
                        cursor: pointer;

                        @include tablet {
                            width: 120px;
                            height: 60px;
                            margin-top: .4em;
                        }

                        .icon-lecture {
                            background: rgba(19, 19, 19, 0.541);
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 5;

                            i {                        
                                color: rgba(255, 255, 255, 0.635);
                                font-size: 1.4em;
                            }                           
                        }

                        img {
                            position: relative;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .item-0 {
                        transform: translateX(640px) translateY(-25px);
                        opacity: 0.65;
                        
                        @include tablet {
                            transform: translateX(520px) translateY(-25px);                           
                        }

                        @include mobile {
                            transform: translateX(20px) translateY(235px);                                                       
                        }                             
                    }

                    .item-1 {
                        transform: translateX(480px) translateY(-20px);
                        opacity: 0.70;

                        @include tablet {
                            transform: translateX(390px) translateY(-20px);                           
                        } 
                        
                        @include mobile {
                            transform: translateX(15px) translateY(175px);                                                       
                        }                         
                    }

                    .item-2 {
                        transform: translateX(320px) translateY(-15px);
                        opacity: 0.75;

                        @include tablet {
                            transform: translateX(260px) translateY(-15px);                           
                        }

                        @include mobile {
                            transform: translateX(10px) translateY(115px);                                                       
                        }                                                   
                    }

                    .item-3 {
                        transform: translateX(160px) translateY(-10px);
                        opacity: 0.80;

                        @include tablet {
                            transform: translateX(130px) translateY(-10px);                           
                        }

                        @include mobile {
                            transform: translateX(5px) translateY(55px);                                                       
                        }                        
                    }

                    .item-4 {
                        transform: translateX(0px) translateY(-5px);
                    }

                    .item-0, .item-1, .item-2, .item-3, .item-4 {
                        &:hover {
                            transform: translateY(-10px);

                            @include mobile {
                                transform: translateY(0);
                            }
                        }
                    }
                }
            }
        }

        .video-text {
            position: relative;
            z-index: 5;
            margin-top: -45px;
            padding: 0 1em;

            @include mobile {
                margin-top: -30px;
            }

            h2 {
                font-size: 5em;
                color: $color-secondary;
                letter-spacing: .5em;

                @include mobile {
                    font-size: 3.5em;
                }                
            }

            .video-description {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                gap: 3em;

                @include mobile {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 1.5em;
                }

                p {
                    color: white;
                    font-size: 1em;
                    width: 60%;

                    @include mobile {
                        font-size: .9em;
                        width: 100%;
                    }
                }
            }
        }
    }
</style>